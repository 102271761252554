import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _54172258 = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _a5869440 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _a3a65d4a = () => interopDefault(import('../pages/download.vue' /* webpackChunkName: "pages/download" */))
const _c959012a = () => interopDefault(import('../pages/download/bill.vue' /* webpackChunkName: "pages/download/bill" */))
const _7563a232 = () => interopDefault(import('../pages/download/consumption.vue' /* webpackChunkName: "pages/download/consumption" */))
const _6c7fb2d8 = () => interopDefault(import('../pages/download/delivery.vue' /* webpackChunkName: "pages/download/delivery" */))
const _425004c3 = () => interopDefault(import('../pages/download/menu.vue' /* webpackChunkName: "pages/download/menu" */))
const _b875bbee = () => interopDefault(import('../pages/download/payment-total.vue' /* webpackChunkName: "pages/download/payment-total" */))
const _ae5e0b4e = () => interopDefault(import('../pages/download/yasaipay.vue' /* webpackChunkName: "pages/download/yasaipay" */))
const _6043aea6 = () => interopDefault(import('../pages/invoice/index.vue' /* webpackChunkName: "pages/invoice/index" */))
const _5958e5b4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _fe844c10 = () => interopDefault(import('../pages/mobile/index.vue' /* webpackChunkName: "pages/mobile/index" */))
const _b3e45cf2 = () => interopDefault(import('../pages/outage/index.vue' /* webpackChunkName: "pages/outage/index" */))
const _ed5f66d8 = () => interopDefault(import('../pages/passwordreset/index.vue' /* webpackChunkName: "pages/passwordreset/index" */))
const _3ec8900a = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _26f6c655 = () => interopDefault(import('../pages/calendar/delivery-confirm.vue' /* webpackChunkName: "pages/calendar/delivery-confirm" */))
const _5bfd4a6a = () => interopDefault(import('../pages/calendar/select.vue' /* webpackChunkName: "pages/calendar/select" */))
const _a071dd94 = () => interopDefault(import('../pages/company/detail.vue' /* webpackChunkName: "pages/company/detail" */))
const _39cf935a = () => interopDefault(import('../pages/company/info.vue' /* webpackChunkName: "pages/company/info" */))
const _6f77b2d0 = () => interopDefault(import('../pages/company/manager.vue' /* webpackChunkName: "pages/company/manager" */))
const _3a32af5e = () => interopDefault(import('../pages/company/owner.vue' /* webpackChunkName: "pages/company/owner" */))
const _ba905fca = () => interopDefault(import('../pages/contact/complete.vue' /* webpackChunkName: "pages/contact/complete" */))
const _85b96b72 = () => interopDefault(import('../pages/cutlery/history/index.vue' /* webpackChunkName: "pages/cutlery/history/index" */))
const _73e5bcfc = () => interopDefault(import('../pages/cutlery/select.vue' /* webpackChunkName: "pages/cutlery/select" */))
const _49bdd6a0 = () => interopDefault(import('../pages/lp/drink.vue' /* webpackChunkName: "pages/lp/drink" */))
const _ea631732 = () => interopDefault(import('../pages/lp/oyatsu.vue' /* webpackChunkName: "pages/lp/oyatsu" */))
const _176e04b2 = () => interopDefault(import('../pages/lp/petit-gohan.vue' /* webpackChunkName: "pages/lp/petit-gohan" */))
const _8547caa8 = () => interopDefault(import('../pages/lp/planup.vue' /* webpackChunkName: "pages/lp/planup" */))
const _8d85d5ac = () => interopDefault(import('../pages/lp/spot.vue' /* webpackChunkName: "pages/lp/spot" */))
const _cceecb18 = () => interopDefault(import('../pages/mobile/order.vue' /* webpackChunkName: "pages/mobile/order" */))
const _0c18b0a4 = () => interopDefault(import('../pages/mobile/products.vue' /* webpackChunkName: "pages/mobile/products" */))
const _4aff5e67 = () => interopDefault(import('../pages/mobile/result.vue' /* webpackChunkName: "pages/mobile/result" */))
const _1076470a = () => interopDefault(import('../pages/news/detail.vue' /* webpackChunkName: "pages/news/detail" */))
const _199ca517 = () => interopDefault(import('../pages/news/list.vue' /* webpackChunkName: "pages/news/list" */))
const _754dfd60 = () => interopDefault(import('../pages/odg/addon.vue' /* webpackChunkName: "pages/odg/addon" */))
const _8b04055e = () => interopDefault(import('../pages/odg/calendar/index.vue' /* webpackChunkName: "pages/odg/calendar/index" */))
const _610d3ae2 = () => interopDefault(import('../pages/odg/cutlery.vue' /* webpackChunkName: "pages/odg/cutlery" */))
const _6cb82218 = () => interopDefault(import('../pages/odg/cutlery-complete.vue' /* webpackChunkName: "pages/odg/cutlery-complete" */))
const _cb1ab992 = () => interopDefault(import('../pages/odg/history/index.vue' /* webpackChunkName: "pages/odg/history/index" */))
const _7d2f67a2 = () => interopDefault(import('../pages/odg/request.vue' /* webpackChunkName: "pages/odg/request" */))
const _19586e90 = () => interopDefault(import('../pages/odg/request-basic.vue' /* webpackChunkName: "pages/odg/request-basic" */))
const _0b79f772 = () => interopDefault(import('../pages/odg/request-complete.vue' /* webpackChunkName: "pages/odg/request-complete" */))
const _14df920c = () => interopDefault(import('../pages/odg/select.vue' /* webpackChunkName: "pages/odg/select" */))
const _e3af058e = () => interopDefault(import('../pages/odg/stock/index.vue' /* webpackChunkName: "pages/odg/stock/index" */))
const _07c5b13f = () => interopDefault(import('../pages/odo/history/index.vue' /* webpackChunkName: "pages/odo/history/index" */))
const _35808037 = () => interopDefault(import('../pages/odo/request.vue' /* webpackChunkName: "pages/odo/request" */))
const _2e70d93f = () => interopDefault(import('../pages/odo/request-complete.vue' /* webpackChunkName: "pages/odo/request-complete" */))
const _106d82c9 = () => interopDefault(import('../pages/ody/history/index.vue' /* webpackChunkName: "pages/ody/history/index" */))
const _f2c27d7e = () => interopDefault(import('../pages/ody/request.vue' /* webpackChunkName: "pages/ody/request" */))
const _6faa2375 = () => interopDefault(import('../pages/ody/request-complete.vue' /* webpackChunkName: "pages/ody/request-complete" */))
const _c1459a8c = () => interopDefault(import('../pages/ody/select.vue' /* webpackChunkName: "pages/ody/select" */))
const _74f13550 = () => interopDefault(import('../pages/option/history/index.vue' /* webpackChunkName: "pages/option/history/index" */))
const _3a60a508 = () => interopDefault(import('../pages/option/request.vue' /* webpackChunkName: "pages/option/request" */))
const _696e8f8e = () => interopDefault(import('../pages/option/request-complete.vue' /* webpackChunkName: "pages/option/request-complete" */))
const _21078bda = () => interopDefault(import('../pages/option/select.vue' /* webpackChunkName: "pages/option/select" */))
const _2e464ce7 = () => interopDefault(import('../pages/passwordreset/complete.vue' /* webpackChunkName: "pages/passwordreset/complete" */))
const _b0b38e3c = () => interopDefault(import('../pages/passwordreset/new.vue' /* webpackChunkName: "pages/passwordreset/new" */))
const _19b780c5 = () => interopDefault(import('../pages/passwordreset/success.vue' /* webpackChunkName: "pages/passwordreset/success" */))
const _4fcd6a44 = () => interopDefault(import('../pages/remote/history.vue' /* webpackChunkName: "pages/remote/history" */))
const _5126f3c4 = () => interopDefault(import('../pages/remote/list.vue' /* webpackChunkName: "pages/remote/list" */))
const _329c80c9 = () => interopDefault(import('../pages/report/detail.vue' /* webpackChunkName: "pages/report/detail" */))
const _47b341f6 = () => interopDefault(import('../pages/static/maintenance.vue' /* webpackChunkName: "pages/static/maintenance" */))
const _5aa9dd57 = () => interopDefault(import('../pages/cutlery/history/select.vue' /* webpackChunkName: "pages/cutlery/history/select" */))
const _e3739090 = () => interopDefault(import('../pages/odg/calendar/delivery-confirm.vue' /* webpackChunkName: "pages/odg/calendar/delivery-confirm" */))
const _2d94bc62 = () => interopDefault(import('../pages/odg/calendar/detail.vue' /* webpackChunkName: "pages/odg/calendar/detail" */))
const _08a58b8d = () => interopDefault(import('../pages/odg/calendar/select.vue' /* webpackChunkName: "pages/odg/calendar/select" */))
const _2745a267 = () => interopDefault(import('../pages/odg/history/select.vue' /* webpackChunkName: "pages/odg/history/select" */))
const _ab69eeb6 = () => interopDefault(import('../pages/odg/stock/select.vue' /* webpackChunkName: "pages/odg/stock/select" */))
const _70a6b515 = () => interopDefault(import('../pages/ody/history/select.vue' /* webpackChunkName: "pages/ody/history/select" */))
const _c6cd5924 = () => interopDefault(import('../pages/option/history/select.vue' /* webpackChunkName: "pages/option/history/select" */))
const _493e3812 = () => interopDefault(import('../pages/odg/history/cutlery/_id.vue' /* webpackChunkName: "pages/odg/history/cutlery/_id" */))
const _6654553f = () => interopDefault(import('../pages/odg/history/product/_id.vue' /* webpackChunkName: "pages/odg/history/product/_id" */))
const _f0859b22 = () => interopDefault(import('../pages/cutlery/history/_id.vue' /* webpackChunkName: "pages/cutlery/history/_id" */))
const _09abea0f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4323766c = () => interopDefault(import('../layouts/error.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/calendar",
    component: _54172258,
    name: "calendar"
  }, {
    path: "/contact",
    component: _a5869440,
    name: "contact"
  }, {
    path: "/download",
    component: _a3a65d4a,
    name: "download",
    children: [{
      path: "bill",
      component: _c959012a,
      name: "download-bill"
    }, {
      path: "consumption",
      component: _7563a232,
      name: "download-consumption"
    }, {
      path: "delivery",
      component: _6c7fb2d8,
      name: "download-delivery"
    }, {
      path: "menu",
      component: _425004c3,
      name: "download-menu"
    }, {
      path: "payment-total",
      component: _b875bbee,
      name: "download-payment-total"
    }, {
      path: "yasaipay",
      component: _ae5e0b4e,
      name: "download-yasaipay"
    }]
  }, {
    path: "/invoice",
    component: _6043aea6,
    name: "invoice"
  }, {
    path: "/login",
    component: _5958e5b4,
    name: "login"
  }, {
    path: "/mobile",
    component: _fe844c10,
    name: "mobile"
  }, {
    path: "/outage",
    component: _b3e45cf2,
    name: "outage"
  }, {
    path: "/passwordreset",
    component: _ed5f66d8,
    name: "passwordreset"
  }, {
    path: "/report",
    component: _3ec8900a,
    name: "report"
  }, {
    path: "/calendar/delivery-confirm",
    component: _26f6c655,
    name: "calendar-delivery-confirm"
  }, {
    path: "/calendar/select",
    component: _5bfd4a6a,
    name: "calendar-select"
  }, {
    path: "/company/detail",
    component: _a071dd94,
    name: "company-detail"
  }, {
    path: "/company/info",
    component: _39cf935a,
    name: "company-info"
  }, {
    path: "/company/manager",
    component: _6f77b2d0,
    name: "company-manager"
  }, {
    path: "/company/owner",
    component: _3a32af5e,
    name: "company-owner"
  }, {
    path: "/contact/complete",
    component: _ba905fca,
    name: "contact-complete"
  }, {
    path: "/cutlery/history",
    component: _85b96b72,
    name: "cutlery-history"
  }, {
    path: "/cutlery/select",
    component: _73e5bcfc,
    name: "cutlery-select"
  }, {
    path: "/lp/drink",
    component: _49bdd6a0,
    name: "lp-drink"
  }, {
    path: "/lp/oyatsu",
    component: _ea631732,
    name: "lp-oyatsu"
  }, {
    path: "/lp/petit-gohan",
    component: _176e04b2,
    name: "lp-petit-gohan"
  }, {
    path: "/lp/planup",
    component: _8547caa8,
    name: "lp-planup"
  }, {
    path: "/lp/spot",
    component: _8d85d5ac,
    name: "lp-spot"
  }, {
    path: "/mobile/order",
    component: _cceecb18,
    name: "mobile-order"
  }, {
    path: "/mobile/products",
    component: _0c18b0a4,
    name: "mobile-products"
  }, {
    path: "/mobile/result",
    component: _4aff5e67,
    name: "mobile-result"
  }, {
    path: "/news/detail",
    component: _1076470a,
    name: "news-detail"
  }, {
    path: "/news/list",
    component: _199ca517,
    name: "news-list"
  }, {
    path: "/odg/addon",
    component: _754dfd60,
    name: "odg-addon"
  }, {
    path: "/odg/calendar",
    component: _8b04055e,
    name: "odg-calendar"
  }, {
    path: "/odg/cutlery",
    component: _610d3ae2,
    name: "odg-cutlery"
  }, {
    path: "/odg/cutlery-complete",
    component: _6cb82218,
    name: "odg-cutlery-complete"
  }, {
    path: "/odg/history",
    component: _cb1ab992,
    name: "odg-history"
  }, {
    path: "/odg/request",
    component: _7d2f67a2,
    name: "odg-request"
  }, {
    path: "/odg/request-basic",
    component: _19586e90,
    name: "odg-request-basic"
  }, {
    path: "/odg/request-complete",
    component: _0b79f772,
    name: "odg-request-complete"
  }, {
    path: "/odg/select",
    component: _14df920c,
    name: "odg-select"
  }, {
    path: "/odg/stock",
    component: _e3af058e,
    name: "odg-stock"
  }, {
    path: "/odo/history",
    component: _07c5b13f,
    name: "odo-history"
  }, {
    path: "/odo/request",
    component: _35808037,
    name: "odo-request"
  }, {
    path: "/odo/request-complete",
    component: _2e70d93f,
    name: "odo-request-complete"
  }, {
    path: "/ody/history",
    component: _106d82c9,
    name: "ody-history"
  }, {
    path: "/ody/request",
    component: _f2c27d7e,
    name: "ody-request"
  }, {
    path: "/ody/request-complete",
    component: _6faa2375,
    name: "ody-request-complete"
  }, {
    path: "/ody/select",
    component: _c1459a8c,
    name: "ody-select"
  }, {
    path: "/option/history",
    component: _74f13550,
    name: "option-history"
  }, {
    path: "/option/request",
    component: _3a60a508,
    name: "option-request"
  }, {
    path: "/option/request-complete",
    component: _696e8f8e,
    name: "option-request-complete"
  }, {
    path: "/option/select",
    component: _21078bda,
    name: "option-select"
  }, {
    path: "/passwordreset/complete",
    component: _2e464ce7,
    name: "passwordreset-complete"
  }, {
    path: "/passwordreset/new",
    component: _b0b38e3c,
    name: "passwordreset-new"
  }, {
    path: "/passwordreset/success",
    component: _19b780c5,
    name: "passwordreset-success"
  }, {
    path: "/remote/history",
    component: _4fcd6a44,
    name: "remote-history"
  }, {
    path: "/remote/list",
    component: _5126f3c4,
    name: "remote-list"
  }, {
    path: "/report/detail",
    component: _329c80c9,
    name: "report-detail"
  }, {
    path: "/static/maintenance",
    component: _47b341f6,
    name: "static-maintenance"
  }, {
    path: "/cutlery/history/select",
    component: _5aa9dd57,
    name: "cutlery-history-select"
  }, {
    path: "/odg/calendar/delivery-confirm",
    component: _e3739090,
    name: "odg-calendar-delivery-confirm"
  }, {
    path: "/odg/calendar/detail",
    component: _2d94bc62,
    name: "odg-calendar-detail"
  }, {
    path: "/odg/calendar/select",
    component: _08a58b8d,
    name: "odg-calendar-select"
  }, {
    path: "/odg/history/select",
    component: _2745a267,
    name: "odg-history-select"
  }, {
    path: "/odg/stock/select",
    component: _ab69eeb6,
    name: "odg-stock-select"
  }, {
    path: "/ody/history/select",
    component: _70a6b515,
    name: "ody-history-select"
  }, {
    path: "/option/history/select",
    component: _c6cd5924,
    name: "option-history-select"
  }, {
    path: "/odg/history/cutlery/:id?",
    component: _493e3812,
    name: "odg-history-cutlery-id"
  }, {
    path: "/odg/history/product/:id?",
    component: _6654553f,
    name: "odg-history-product-id"
  }, {
    path: "/cutlery/history/:id",
    component: _f0859b22,
    name: "cutlery-history-id"
  }, {
    path: "/",
    component: _09abea0f,
    name: "index"
  }, {
    path: "*",
    component: _4323766c,
    name: "custom"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
