export const state = () => ({
  siteURL: '',
  calendarDate: null,
  downloadList: [],
})

export const mutations = {
  updateCalendarDate(state, value) {
    state.calendarDate = value
  },
  updateDownloadList(state, value) {
    state.downloadList = value
  },
}
