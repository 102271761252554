export default function ({ $sentry }, inject) {
  inject('report', ({ title = 'report', tag, event }) => {
    if (process.env.isEnableReport !== 'true') {
      return
    }

    $sentry.captureMessage(title, (scope) => {
      scope.clear()
      // tag = click, submit, input
      scope.setTag('report', tag)
      scope.setContext('detail', event)

      return scope
    })
  })

  inject('reportJson', ({ title = 'report', tag, event }) => {
    if (process.env.isEnableReport !== 'true') {
      return
    }

    $sentry.captureMessage(title, (scope) => {
      scope.clear()
      // tag = click, submit, input
      scope.setTag('report', tag)
      scope.addAttachment({
        filename: `${title}.json`,
        data: event,
      })

      return scope
    })
  })
}
