export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const LayoutWrap = () => import('../../components/LayoutWrap.vue' /* webpackChunkName: "components/layout-wrap" */).then(c => wrapFunctional(c.default || c))
export const SideNav = () => import('../../components/SideNav.vue' /* webpackChunkName: "components/side-nav" */).then(c => wrapFunctional(c.default || c))
export const ImageRich = () => import('../../components/Image/Rich.vue' /* webpackChunkName: "components/image-rich" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/Tooltip/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const AdBanners = () => import('../../components/ad/AdBanners.vue' /* webpackChunkName: "components/ad-banners" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/button/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const ButtonGroup = () => import('../../components/button/ButtonGroup.vue' /* webpackChunkName: "components/button-group" */).then(c => wrapFunctional(c.default || c))
export const Calendar = () => import('../../components/calendar/Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/card/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CardApply = () => import('../../components/card/CardApply.vue' /* webpackChunkName: "components/card-apply" */).then(c => wrapFunctional(c.default || c))
export const CardContact = () => import('../../components/card/CardContact.vue' /* webpackChunkName: "components/card-contact" */).then(c => wrapFunctional(c.default || c))
export const CardReserve = () => import('../../components/card/CardReserve.vue' /* webpackChunkName: "components/card-reserve" */).then(c => wrapFunctional(c.default || c))
export const ChartCat = () => import('../../components/chart/ChartCat.vue' /* webpackChunkName: "components/chart-cat" */).then(c => wrapFunctional(c.default || c))
export const ChartCollection = () => import('../../components/chart/ChartCollection.vue' /* webpackChunkName: "components/chart-collection" */).then(c => wrapFunctional(c.default || c))
export const ChartDelivery = () => import('../../components/chart/ChartDelivery.vue' /* webpackChunkName: "components/chart-delivery" */).then(c => wrapFunctional(c.default || c))
export const ChartDownload = () => import('../../components/chart/ChartDownload.vue' /* webpackChunkName: "components/chart-download" */).then(c => wrapFunctional(c.default || c))
export const ChartPay = () => import('../../components/chart/ChartPay.vue' /* webpackChunkName: "components/chart-pay" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/checkbox/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const Col = () => import('../../components/col/Col.vue' /* webpackChunkName: "components/col" */).then(c => wrapFunctional(c.default || c))
export const DateDayPager = () => import('../../components/date/DateDayPager.vue' /* webpackChunkName: "components/date-day-pager" */).then(c => wrapFunctional(c.default || c))
export const DateMonthPager = () => import('../../components/date/DateMonthPager.vue' /* webpackChunkName: "components/date-month-pager" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../components/date/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const DateYearPager = () => import('../../components/date/DateYearPager.vue' /* webpackChunkName: "components/date-year-pager" */).then(c => wrapFunctional(c.default || c))
export const Feedback = () => import('../../components/feedback/index.vue' /* webpackChunkName: "components/feedback" */).then(c => wrapFunctional(c.default || c))
export const Form = () => import('../../components/form/Form.vue' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c))
export const FormItem = () => import('../../components/form/FormItem.vue' /* webpackChunkName: "components/form-item" */).then(c => wrapFunctional(c.default || c))
export const FormMenuDownload = () => import('../../components/form/FormMenuDownload.vue' /* webpackChunkName: "components/form-menu-download" */).then(c => wrapFunctional(c.default || c))
export const FormView = () => import('../../components/form/FormView.vue' /* webpackChunkName: "components/form-view" */).then(c => wrapFunctional(c.default || c))
export const FormViewItem = () => import('../../components/form/FormViewItem.vue' /* webpackChunkName: "components/form-view-item" */).then(c => wrapFunctional(c.default || c))
export const GoodsBaseItem = () => import('../../components/goods/GoodsBaseItem.vue' /* webpackChunkName: "components/goods-base-item" */).then(c => wrapFunctional(c.default || c))
export const GoodsHistoryItem = () => import('../../components/goods/GoodsHistoryItem.vue' /* webpackChunkName: "components/goods-history-item" */).then(c => wrapFunctional(c.default || c))
export const GoodsHistoryList = () => import('../../components/goods/GoodsHistoryList.vue' /* webpackChunkName: "components/goods-history-list" */).then(c => wrapFunctional(c.default || c))
export const GoodsHistoryTable = () => import('../../components/goods/GoodsHistoryTable.vue' /* webpackChunkName: "components/goods-history-table" */).then(c => wrapFunctional(c.default || c))
export const GoodsOdgGird = () => import('../../components/goods/GoodsOdgGird.vue' /* webpackChunkName: "components/goods-odg-gird" */).then(c => wrapFunctional(c.default || c))
export const GoodsOdgGirdItem = () => import('../../components/goods/GoodsOdgGirdItem.vue' /* webpackChunkName: "components/goods-odg-gird-item" */).then(c => wrapFunctional(c.default || c))
export const GoodsOdgItem = () => import('../../components/goods/GoodsOdgItem.vue' /* webpackChunkName: "components/goods-odg-item" */).then(c => wrapFunctional(c.default || c))
export const GoodsOdgList = () => import('../../components/goods/GoodsOdgList.vue' /* webpackChunkName: "components/goods-odg-list" */).then(c => wrapFunctional(c.default || c))
export const GoodsOdoItem = () => import('../../components/goods/GoodsOdoItem.vue' /* webpackChunkName: "components/goods-odo-item" */).then(c => wrapFunctional(c.default || c))
export const GoodsOdoList = () => import('../../components/goods/GoodsOdoList.vue' /* webpackChunkName: "components/goods-odo-list" */).then(c => wrapFunctional(c.default || c))
export const GoodsOdyItem = () => import('../../components/goods/GoodsOdyItem.vue' /* webpackChunkName: "components/goods-ody-item" */).then(c => wrapFunctional(c.default || c))
export const GoodsOdyList = () => import('../../components/goods/GoodsOdyList.vue' /* webpackChunkName: "components/goods-ody-list" */).then(c => wrapFunctional(c.default || c))
export const GoodsStockTable = () => import('../../components/goods/GoodsStockTable.vue' /* webpackChunkName: "components/goods-stock-table" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../components/input/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const InputNumber = () => import('../../components/input/InputNumber.vue' /* webpackChunkName: "components/input-number" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../components/link/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const ListAd = () => import('../../components/list/ListAd.vue' /* webpackChunkName: "components/list-ad" */).then(c => wrapFunctional(c.default || c))
export const ListCase = () => import('../../components/list/ListCase.vue' /* webpackChunkName: "components/list-case" */).then(c => wrapFunctional(c.default || c))
export const ListCaseItem = () => import('../../components/list/ListCaseItem.vue' /* webpackChunkName: "components/list-case-item" */).then(c => wrapFunctional(c.default || c))
export const ListGird = () => import('../../components/list/ListGird.vue' /* webpackChunkName: "components/list-gird" */).then(c => wrapFunctional(c.default || c))
export const ListGoods = () => import('../../components/list/ListGoods.vue' /* webpackChunkName: "components/list-goods" */).then(c => wrapFunctional(c.default || c))
export const ListGoodsItem = () => import('../../components/list/ListGoodsItem.vue' /* webpackChunkName: "components/list-goods-item" */).then(c => wrapFunctional(c.default || c))
export const ListLabel = () => import('../../components/list/ListLabel.vue' /* webpackChunkName: "components/list-label" */).then(c => wrapFunctional(c.default || c))
export const ListLabelItem = () => import('../../components/list/ListLabelItem.vue' /* webpackChunkName: "components/list-label-item" */).then(c => wrapFunctional(c.default || c))
export const ListNews = () => import('../../components/list/ListNews.vue' /* webpackChunkName: "components/list-news" */).then(c => wrapFunctional(c.default || c))
export const ListProductIntro = () => import('../../components/list/ListProductIntro.vue' /* webpackChunkName: "components/list-product-intro" */).then(c => wrapFunctional(c.default || c))
export const ListProductMenu = () => import('../../components/list/ListProductMenu.vue' /* webpackChunkName: "components/list-product-menu" */).then(c => wrapFunctional(c.default || c))
export const ListProductRichTable = () => import('../../components/list/ListProductRichTable.vue' /* webpackChunkName: "components/list-product-rich-table" */).then(c => wrapFunctional(c.default || c))
export const ListProductTable = () => import('../../components/list/ListProductTable.vue' /* webpackChunkName: "components/list-product-table" */).then(c => wrapFunctional(c.default || c))
export const ListPush = () => import('../../components/list/ListPush.vue' /* webpackChunkName: "components/list-push" */).then(c => wrapFunctional(c.default || c))
export const MobileFeature = () => import('../../components/mobile/MobileFeature.vue' /* webpackChunkName: "components/mobile-feature" */).then(c => wrapFunctional(c.default || c))
export const MobileHeader = () => import('../../components/mobile/MobileHeader.vue' /* webpackChunkName: "components/mobile-header" */).then(c => wrapFunctional(c.default || c))
export const MobileListAd = () => import('../../components/mobile/MobileListAd.vue' /* webpackChunkName: "components/mobile-list-ad" */).then(c => wrapFunctional(c.default || c))
export const MobileProduct = () => import('../../components/mobile/MobileProduct.vue' /* webpackChunkName: "components/mobile-product" */).then(c => wrapFunctional(c.default || c))
export const MobileProductCard = () => import('../../components/mobile/MobileProductCard.vue' /* webpackChunkName: "components/mobile-product-card" */).then(c => wrapFunctional(c.default || c))
export const MobileGoodsItem = () => import('../../components/mobile/goodsItem.vue' /* webpackChunkName: "components/mobile-goods-item" */).then(c => wrapFunctional(c.default || c))
export const ModalMenuDownload = () => import('../../components/modal/ModalMenuDownload.vue' /* webpackChunkName: "components/modal-menu-download" */).then(c => wrapFunctional(c.default || c))
export const Option = () => import('../../components/option/Option.vue' /* webpackChunkName: "components/option" */).then(c => wrapFunctional(c.default || c))
export const PageHeadActions = () => import('../../components/page/PageHeadActions.vue' /* webpackChunkName: "components/page-head-actions" */).then(c => wrapFunctional(c.default || c))
export const PageLogin = () => import('../../components/page/PageLogin.vue' /* webpackChunkName: "components/page-login" */).then(c => wrapFunctional(c.default || c))
export const PageWrap = () => import('../../components/page/PageWrap.vue' /* webpackChunkName: "components/page-wrap" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/pagination/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const Radio = () => import('../../components/radio/Radio.vue' /* webpackChunkName: "components/radio" */).then(c => wrapFunctional(c.default || c))
export const RadioButton = () => import('../../components/radio/RadioButton.vue' /* webpackChunkName: "components/radio-button" */).then(c => wrapFunctional(c.default || c))
export const RadioGroup = () => import('../../components/radio/RadioGroup.vue' /* webpackChunkName: "components/radio-group" */).then(c => wrapFunctional(c.default || c))
export const RadioOptions = () => import('../../components/radio/RadioOptions.vue' /* webpackChunkName: "components/radio-options" */).then(c => wrapFunctional(c.default || c))
export const Row = () => import('../../components/row/Row.vue' /* webpackChunkName: "components/row" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../components/select/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const Steps = () => import('../../components/steps/Steps.vue' /* webpackChunkName: "components/steps" */).then(c => wrapFunctional(c.default || c))
export const StepsEnd = () => import('../../components/steps/StepsEnd.vue' /* webpackChunkName: "components/steps-end" */).then(c => wrapFunctional(c.default || c))
export const TableCatSummary = () => import('../../components/table/TableCatSummary.vue' /* webpackChunkName: "components/table-cat-summary" */).then(c => wrapFunctional(c.default || c))
export const TableGoods = () => import('../../components/table/TableGoods.vue' /* webpackChunkName: "components/table-goods" */).then(c => wrapFunctional(c.default || c))
export const TableInvoice = () => import('../../components/table/TableInvoice.vue' /* webpackChunkName: "components/table-invoice" */).then(c => wrapFunctional(c.default || c))
export const TableMonthCat = () => import('../../components/table/TableMonthCat.vue' /* webpackChunkName: "components/table-month-cat" */).then(c => wrapFunctional(c.default || c))
export const TableMonthSummary = () => import('../../components/table/TableMonthSummary.vue' /* webpackChunkName: "components/table-month-summary" */).then(c => wrapFunctional(c.default || c))
export const TableOyatsuPrice = () => import('../../components/table/TableOyatsuPrice.vue' /* webpackChunkName: "components/table-oyatsu-price" */).then(c => wrapFunctional(c.default || c))
export const TableRemote = () => import('../../components/table/TableRemote.vue' /* webpackChunkName: "components/table-remote" */).then(c => wrapFunctional(c.default || c))
export const TableRemoteHistory = () => import('../../components/table/TableRemoteHistory.vue' /* webpackChunkName: "components/table-remote-history" */).then(c => wrapFunctional(c.default || c))
export const TableReportYear = () => import('../../components/table/TableReportYear.vue' /* webpackChunkName: "components/table-report-year" */).then(c => wrapFunctional(c.default || c))
export const TableYasaipayHistory = () => import('../../components/table/TableYasaipayHistory.vue' /* webpackChunkName: "components/table-yasaipay-history" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/tabs/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../components/tag/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const TagList = () => import('../../components/tag/TagList.vue' /* webpackChunkName: "components/tag-list" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
