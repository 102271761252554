import Vue from 'vue'
import {
  Menu,
  Submenu,
  MenuItem,
  Tooltip,
  Message,
  MessageBox,
  Loading,
  Dialog,
  Drawer,
  CheckboxGroup,
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/ja'
import locale from 'element-ui/lib/locale'
import '../assets/sass/element-theme.scss'

const components = [
  Menu,
  Submenu,
  MenuItem,
  Tooltip,
  Dialog,
  Drawer,
  CheckboxGroup,
]

const Element = {
  install(Vue) {
    components.forEach((component) => {
      Vue.component(component.name, component)
    })
  },
}

// Global context
Vue.prototype.$message = Message
Vue.prototype.$messageBox = MessageBox

Vue.prototype.$toast = (opts) => {
  return Message({
    ...opts,
    type: 'info',
    customClass: 'toast',
  })
}

Vue.prototype.$confirm = (text = '', title = '確認', opts) => {
  return MessageBox.confirm(text, title, {
    confirmButtonText: '注文する',
    cancelButtonText: '前に戻る',
    showClose: false,
    type: '',
    customClass: 'message-box-confirm',
    confirmButtonClass: 'el-button--confirm',
    cancelButtonClass: 'el-button--info',
    ...opts,
  })
}

// Use Loading
Vue.use(Loading)
Vue.use(Loading.directive)

// Set language
locale.use(lang)

Vue.use(Element)
