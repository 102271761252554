export default async function ({ $auth, $axios, store, route }) {
  const isCodeLogin = !!(route.path === '/login' && route.query.code)

  if (!isCodeLogin && $auth.loggedIn && store.state.downloadList.length < 1) {
    const res = await $axios.$get('/download/list')

    const { list } = res.data

    if (list) {
      store.commit('updateDownloadList', list)
    }
  }
}
