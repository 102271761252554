import Vue from 'vue'
import { format } from 'date-fns'
import { ja } from 'date-fns/locale'

export const formatDate = (date, formatText, options) => {
  if (typeof date === 'string') {
    // fixed ios time
    date = new Date(date.replace(' ', 'T'))
  }

  try {
    return format(
      date,
      formatText,
      Object.assign(
        {
          locale: ja,
        },
        options
      )
    )
  } catch (e) {
    return ''
  }
}

const digitsRE = /(\d{3})(?=\d)/g

export const currency = (value, unit = '円', decimals = 0) => {
  value = parseFloat(value)

  if (!value && value !== 0) {
    return ''
  }

  const stringified = Math.abs(value).toFixed(decimals)
  const $int = decimals ? stringified.slice(0, -1 - decimals) : stringified
  const i = $int.length % 3
  const head = i > 0 ? $int.slice(0, i) + ($int.length > 3 ? ',' : '') : ''
  const $float = decimals ? stringified.slice(-1 - decimals) : ''
  const sign = value < 0 ? '-' : ''

  return `${sign}${head}${$int
    .slice(i)
    .replace(digitsRE, '$1,')}${$float} ${unit}`
}

const filters = {
  formatDate,
  currency,
}

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

export default filters
