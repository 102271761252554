export default function ({ $auth, store, route, redirect }) {
  if ($auth.loggedIn) {
    const { yasai, gohan, remote, equipment, download } = $auth.user.plans
    const { downloadList } = store.state

    const checkRouterMap = {
      '/calendar': yasai.calendar.display,
      '/calendar/select': yasai.calendar.display,
      '/calendar/delivery-confirm': yasai.calendar.display,
      '/ody/request': yasai.request.display,
      '/ody/select': yasai.request.display,
      '/ody/history': yasai.history.display,
      '/ody/history/select': yasai.history.display,
      '/option/request': yasai.option_request.display,
      '/option/select': yasai.option_request.display,
      '/option/history': yasai.option_history.display,
      '/option/history/select': yasai.option_history.display,
      '/odg/request': gohan.request.display,
      '/odg/select': route.query.isAddon
        ? gohan.addon_request.display
        : gohan.request.display,
      '/odg/request-basic': route.query.isAddon
        ? gohan.addon_request.display
        : gohan.request.display,
      '/odg/addon': gohan.addon_request.display,
      '/odg/history': gohan.history.display,
      '/odg/history/select': gohan.history.display,
      '/odg/history/product/{id}': gohan.history.display,
      '/odg/history/cutlery/{id}': gohan.history.display,
      '/odg/calendar': gohan.calendar.display,
      '/odg/calendar/select': gohan.calendar.display,
      '/odg/calendar/delivery-confirm': gohan.calendar.display,
      '/odg/stock/select': gohan.stock.display,
      '/odg/stock': gohan.stock.display,
      '/remote/list': remote.display,
      '/remote/history': remote.display,
      '/remote/history/select': remote.display,
      '/cutlery/select': equipment.request.display,
      '/cutlery/history': equipment.history.display,
      '/cutlery/history/select': equipment.history.display,
      '/download': download.display,
    }

    if (downloadList?.length) {
      downloadList.forEach((item) => {
        checkRouterMap[`/download/${item.id}`] = item.display
      })
    }

    const params = route.path.match(/.+\/(\d+)$/)?.[1]
    const path = params
      ? route.path.replace(/(.+\/)(\d+)$/, '$1{id}')
      : route.path

    if (checkRouterMap[path] === false) {
      return redirect('/404')
    }
  }
}
