import Html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

export const outputPdf = async (element, filename = 'file.pdf', opts = {}) => {
  const baseOpts = {
    a4Width: 592.28,
    a4Height: 841.89,
    mode: 'download',
    marginBottom: 1,
  }

  opts = {
    ...baseOpts,
    ...opts,
  }
  const A4_WIDTH = opts.a4Width
  const A4_HEIGHT = opts.a4Height

  // unite different screen size
  element.ownerDocument.defaultView.devicePixelRatio = 2
  element.ownerDocument.defaultView.innerWidth = 1440

  const canvas = await Html2Canvas(element, {
    allowTaint: true,
    useCORS: true,
    scale: 2,
  })

  const contentWidth = canvas.width
  const contentHeight = canvas.height

  // 一页pdf显示html页面生成的canvas高度;
  const pageHeight = (contentWidth / A4_WIDTH) * A4_HEIGHT
  // 未生成pdf的html页面高度
  let leftHeight = contentHeight
  // 页面偏移
  let position = 0
  // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
  const imgWidth = A4_WIDTH
  const imgHeight = (A4_WIDTH / contentWidth) * contentHeight

  const pageData = canvas.toDataURL('image/jpeg', 1.0)

  const pdf = new JsPDF('', 'pt', 'a4')

  // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
  // 当内容未超过pdf一页显示的范围，无需分页
  if (leftHeight < pageHeight) {
    pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
  } else {
    while (leftHeight > opts.marginBottom) {
      pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
      leftHeight -= pageHeight
      position -= A4_HEIGHT
      // 避免添加空白页
      if (leftHeight > opts.marginBottom) {
        pdf.addPage()
      }
    }
  }

  if (opts.mode === 'preview') {
    const string = pdf.output('datauristring')
    const embed = "<embed width='100%' height='100%' src='" + string + "'/>"
    const x = window.open()
    x.document.open()
    x.document.write(embed)
    x.document.close()

    return
  }

  pdf.save(filename)
}

export default ({ $axios }, inject) => {
  inject('download', async (downloadUrl) => {
    const res = await $axios.get(downloadUrl, {
      responseType: 'blob',
    })

    if (res.status !== 200) {
      return
    }

    const url = window.URL.createObjectURL(
      new Blob([res.data], { type: 'application/pdf' })
    )
    const link = document.createElement('a')
    const contentDisposition = res.headers['content-disposition']

    let filename = 'file.pdf'

    if (contentDisposition) {
      const regex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/gi
      const matches = []
      let match

      while ((match = regex.exec(contentDisposition)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (match.index === regex.lastIndex) {
          regex.lastIndex++
        }

        // The result can be accessed through the `match` variable.
        matches.push(match)
      }

      // priority use utf8 filename, or use first default
      const filenameAry =
        matches.find((item) => {
          return /utf-8/i.test(item[0])
        }) || matches[0]

      filename = decodeURIComponent(filenameAry[1])
    }

    link.download = filename
    link.href = url
    link.target = '_blank'
    link.click()
  })
}
